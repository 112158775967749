<template>
  <validation-observer
    ref="form"
    #default="{ invalid }"
    tag="form"
    class="repeater__form"
    style="overflow: visible"
  >
    <b-modal
      v-model="show"
      size="lg"
      content-class="package-modal rounded-14 mx-4"
      no-fade
    >
      <template slot="modal-header-close">
        <close-icon />
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('packages.request_special_offer') }}
          </h3>
        </div>
      </template>

      <b-alert show class="rounded-14" variant="warning">
        <div class="alert-body text-reg-14 rounded-14">
          <story-icon class="mr-3"></story-icon>
          <span class="ml-25">{{ $t('packages.check_manually') }}</span>
        </div>
      </b-alert>

      <b-row>
        <b-col md="6">
          <wameed-dropdown
            id="package"
            v-model="selectedPackage"
            :label="$t('packages.the_package_to_be_advertised')"
            :placeholder="
              $t('packages.the_package_to_be_advertised_placeholder')
            "
            classes="text-medium-16 w-100"
            value-classes="  text-medium-16  text-font-secondary"
            title="title"
            variant="disable"
            :items="getAllCouponDropdown"
            text="title"
            name="packageCity"
            rules="required"
          />
        </b-col>
        <b-col md="6">
          <text-input
            id="package-ad"
            v-model="days"
            :label="$t('packages.number_of_advertising_days')"
            :placeholder="$t('packages.number_of_advertising_days_placeholder')"
            name="package-ad"
            rules="required"
          />
        </b-col>
        <b-col md="12">
          <text-area
            id="package-request-reason"
            v-model="requestReason"
            :label="$t('packages.request_reason')"
            :placeholder="$t('packages.request_reason_placeholder')"
            input-classes="text-med-14 text-font-main"
            name="requestReason"
            rules="required"
            rows="5"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
          classes="  text-med-14 text-white mx-0 rounded-10"
          :title="$t('common.send')"
          :disabled="invalid"
          type="submit"
          variant="main"
          @submitAction="submitOrder()"
        />
        <wameed-btn
          classes="  text-med-14 text-font-secondary mx-0 rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          @submitAction="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import { mapGetters, mapActions } from 'vuex';
import TextArea from '../TextArea.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
    TextArea,
    WameedDropdown,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPackage: null,
      packages: [
        {
          id: 1,
          title: 'test',
        },
        {
          id: 2,
          title: 'test2',
        },
      ],
      days: null,
      requestReason: null,
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getAllCouponDropdown: 'getAllCouponDropdown',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['submitChangeStatus']),
    submitOrder() {
      const data = {
        service_id: this.selectedPackage.id,
        day: this.days,
        reason: this.requestReason,
        status: 'feature',
      };
      this.submitChangeStatus(data).then(() => {
        this.$emit('set-message', data);
        this.show = false;
        this.showModal = true;
        this.selectedPackage = null;
        this.days = null;
        this.requestReason = null;
        this.$store.commit('loadingFinish');
      });
    },
    closeModal() {
      this.selectedPackage = null;
      this.days = null;
      this.requestReason = null;
      this.show = false;
    },
  },
};
</script>
