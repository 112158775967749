var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"wameed-input text-reg-14 text-font-main",class:_vm.fieldClasses,attrs:{"label-for":_vm.id}},[(_vm.label)?_c('label',{staticClass:"text-font-main",class:_vm.rules && _vm.rules.split('|').includes('required') ? 'required' : '',attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.subLable)?_c('span',{staticClass:"text-reg-14 text-icon mx-2"},[_vm._v(_vm._s(_vm.subLable))]):_vm._e(),_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"vid":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('v-select',{class:_vm.classes,attrs:{"value":_vm.value,"dir":_vm.$i18n.locale =='ar'?'rtl':'ltr',"label":_vm.title,"multiple":_vm.multiple,"state":errors.length > 0 ? false : null,"name":_vm.id,"options":_vm.items,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"closeOnSelect":!_vm.multiple,"deselectFromDropdown":true,"searchable":false,"disabled":_vm.disabled,"taggable":""},on:{"input":function($event){return _vm.updateValue($event)}},scopedSlots:_vm._u([(_vm.multiple)?{key:"selected-option-container",fn:function(ref){
var option = ref.option;
var deselect = ref.deselect;
return [(
              _vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) <= 3
            )?_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(option[_vm.title])),_c('close-icon',{staticStyle:{"padding":"2px"},on:{"click":function($event){return deselect(option)}}})],1):_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s('+' + (_vm.value.length - 1))+" ")])]}}:null,(_vm.multiple)?{key:"option",fn:function(option){return [_c('b-form-checkbox',{staticClass:"custom-control-main",class:_vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) > -1
                ? 'active'
                : '',attrs:{"checked":_vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) > -1}},[_c('span',{staticClass:"text-reg-14 text-font-sub mx-2"},[_vm._v(_vm._s(option[_vm.title]))])])]}}:null],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }