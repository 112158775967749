<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white rounded-14">
    <h1 class="package-details-table__title text-font-main text-med-14">
      {{ $t('users.providers.detail.sub') }}
    </h1>
    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.user_name') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.name }}</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.account_type') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.account_type }}</p>
      </b-col>
    </b-row>


    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.email') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.email }}</p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.subscription_date') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{   moment(detail.created_at).format('ll') }}</p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.subscriptions') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.subscription_type }}</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.account_status') }}</p>
      </b-col>
      <div class="package-details-value">
        <b-badge
            pill
            :variant="'light-' + status[1][detail.status]"
            :class="'border-' + status[1][detail.status]"
            class="text-reg-12 py-0 px-3"
        >{{ $t('common.' + status[0][detail.status]) }}</b-badge
        >
      </div>
    </b-row>


    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.last_subscription_date') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{   moment(detail.subscription_date).format('ll')}}</p>
      </b-col>
    </b-row>


    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.packages_count') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.packages_count }}</p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.about') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.about }}</p>
      </b-col>
    </b-row>

  </div>
</template>

<script>

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      status: [
        {
          active: 'active',
          not_active: 'not_active',
        },
        {
          active: 'success',
          not_active: 'input',
        },
      ],
    };
  },

};
</script>