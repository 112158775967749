<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
          :title="$t('users.providers.detail.title')"
          :subTitle="$t('users.providers.detail.desc')"
      />
    </div>
    <section class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col md="12" class="my-4">
            <provider-details-table :detail="getUserDetail"/>
          </b-col>
        </b-row>
      </div>
      <provider-packages/>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import ProviderDetailsTable from "@/views/pages/users/providers/components/providerDetailsTable";
import ProviderPackages from "@/views/pages/users/providers/components/providerPackages";

export default {
  components: {
    ProviderPackages,
    ProviderDetailsTable,
    PageHeader,
  },

  computed: {
    ...mapGetters({
      getUserDetail: 'getUserDetail',
    }),
  },
  mounted() {
    this.loadDetail(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadDetail: 'loadProviderDetail',
    }),

  },
};
</script>
