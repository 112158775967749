<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      v-if="getAllPackages"
      :title="$t('nav.packages')"
    />

    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllPackages"
              :per-page="filterData.per_page"
              :items="getAllPackages"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'fullName' },
                { name: 'action' },
                { name: 'start_date' },
                { name: 'end_date' },
                { name: 'registered' },
              ]"
            >
              <template
                slot="fullName"
                slot-scope="{ data }"
              >
                <div class="d-flex justify-center align-items-center">
                  <b-avatar :src="data.item.image" />
                  <span class="text-font-main mx-3">{{ data.item.title }}</span>
                </div>
              </template>

              <template
                slot="start_date"
                slot-scope="{ data }"
              >
                {{ moment(data.item.start_date).format('ll') }}
              </template>
              <template
                slot="end_date"
                slot-scope="{ data }"
              >
                {{ moment(data.item.end_date).format('ll') }}
              </template>

              <template
                slot="registered"
                slot-scope="{ data }"
              >
                <div>
                  {{ data.item.max_people - data.item.available_people }}
                </div>
              </template>

              <template
                slot="action"
                slot-scope="{ data }"
              >
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="packageDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllPackages && getAllPackages.length < 1"
              icon="nodata-icon"
              :title="$t('packages.no_data')"
              :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalPackages.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>

  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import CancelPackageModal from '@/components/PackageDetailsModals/CancelPackageModal.vue';
import SpecialPackageModal from '@/components/PackageDetailsModals/SpecialPackageModal.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
    CancelPackageModal,
    SpecialPackageModal,
    WarningModal,
  },
  data() {
    return {

      filterData: {
        status: '',
        city: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'status',
          id: 'id',
          name: 'title',
          count: null,
          key: 'status',
          selectedData: [],
          data: [
            {
              id: 'soon',
              title: 'soon',
            },
            {
              id: 'active',
              title: 'active',
            },
            {
              id: 'expired',
              title: 'expired',
            },
          ],
        },
        {
          title: 'city',
          key: 'city',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'fullName',
          label: this.$i18n.t('packages.package_name'),
          sortable: true,
        },
        {
          key: 'city',
          label: this.$i18n.t('packages.city'),
          sortable: true,
        },
        {
          key: 'start_date',
          label: this.$i18n.t('packages.start_date'),
          sortable: true,
        },
        {
          key: 'end_date',
          label: this.$i18n.t('packages.end_date'),
          sortable: true,
        },
        {
          key: 'max_people',
          label: this.$i18n.t('packages.individual_count'),
          sortable: true,
        },
        {
          key: 'registered',
          label: this.$i18n.t('packages.registared'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('packages.status'),
          sortable: true,
        },
        { key: 'action', label: '' },
      ],
      status: [
        {
          full: 'full',
          canceled: 'canceled',
          active: 'active',
          not_active: 'not_active',
          soon: 'soon',
          expired: 'expired',
          not_available: 'not_available',
        },
        {
          active: 'success',
          soon: 'warning',
          not_active: 'danger',
          expired: 'danger',
          not_available: 'danger',
          canceled: 'danger',
          full: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllPackages: 'getAllPackages',
      getTotalPackages: 'getTotalPackages',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[1].data = newVal.cities ? newVal.cities : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData({
      data: 'services',
      type: 'provider',
      id: this.$router.currentRoute.params.id,
    });
  },
  methods: {
    ...mapActions({
      loadProviderPackages: 'loadProviderPackages',
      loadFilterData: 'loadInnerFilterData',
    }),

    goto(destination) {
      this.$router.push({ name: destination });
    },
    setRequestMessage(message) {
      this.requestMessage = message;
      this.showSuccessModal = true;
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadProviderPackages(
        {
          ...this.filterData,
          id: this.$router.currentRoute.params.id,
        },
      );
    },
    packageDetails(id) {
      this.$router.push({
        name: 'package-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach(item => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

  },
};
</script>
